import React, {useContext, useState} from 'react';
import {Container, Form} from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import {useLocation, useHistory} from "react-router-dom";
import {LOGIN_ROUTE, SHOP_ROUTE} from "../utils/consts";
import {login} from "../http/userAPI";
import {getData} from "../http/deviceAPI"


import {observer} from "mobx-react-lite";
import {Context} from "../index";

const Auth = observer(() => {
    const {user} = useContext(Context)
    const {device} = useContext(Context)
    const location = useLocation()
    const history = useHistory()
    const isLogin = location.pathname === LOGIN_ROUTE
    const [password, setPassword] = useState('')


    const click = async () => {
        try {
            if (isLogin) {
                await login(password);
            }
           device.setDevices(await getData())

            user.setUser(user)
            user.setIsAuth(true)
            history.push(SHOP_ROUTE)
        } catch (e) {
            alert(e.response.data.message)
        }

    }

    return (
        <Container
            className="d-flex justify-content-center align-items-center"
            style={{height: window.innerHeight - 54 }}
        >
            <Card style={{width: 300, backgroundColor : 'black'}} className="p-2">
                <Form className="d-flex flex-column">

                    <Form.Control
                        className="mt-3"
                        placeholder="Password..."
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        type="password"
                        style={{backgroundColor:'black'}}
                    />
                    <Row className="d-flex justify-content-center mt-3 pl-3 pr-3">

                        <Button
                            variant={"outline-success"}
                            onClick={click}
                        >
                            LogIn
                        </Button>
                    </Row>

                </Form>
            </Card>
        </Container>
    );
});

export default Auth;
