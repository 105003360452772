
import {LOGIN_ROUTE, SHOP_ROUTE} from "./utils/consts";
//import Pages from "./components/Pages";
import Shop from "./pages/Shop";
import Auth from "./pages/Auth";


export const authRoutes = [
    {
        path: SHOP_ROUTE,
        Component: Shop
    },
]

export const publicRoutes = [

    {
        path: LOGIN_ROUTE,
        //Component: Pages
        Component: Auth
    },

]
