import React, {useContext} from 'react';
import {Context} from "../index";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
//import {LOGIN_ROUTE} from "../utils/consts";
import {Button} from "react-bootstrap";
import {observer} from "mobx-react-lite";
import Container from "react-bootstrap/Container";
import {useHistory} from "react-router-dom"
import {getData} from "../http/deviceAPI";



const NavBar = observer(() => {
    const {user} = useContext(Context)
     const history = useHistory()

    const {device} = useContext(Context)

    const logOut = () => {
        user.setUser({})
        user.setIsAuth(false)
        localStorage.setItem('token','')
    }
    return (
        <Navbar className='sticky-top' bg="dark"  variant="dark">
            <Container>

                {user.isAuth ?
                    <Nav className="ml-auto" style={{color: 'white'}}>
                        <Button
                            variant={"outline-light"}
                            onClick={
                                async () => {
                                    try {
                                         device.setDevices(await getData())
                                        } catch (e) {alert(e.response.data.message)}}}
                        >
                            Reload
                        </Button>

                        <Button
                            style={{marginLeft:'5px'}}
                            variant={"outline-light"}
                            onClick={() => logOut()}
                        >
                            Exit
                        </Button>
                    </Nav>
                    :
                    <Nav className="ml-auto" style={{color: 'white'}}>

                        
                    </Nav>
                }
            </Container>
        </Navbar>

    );
});

export default NavBar;
