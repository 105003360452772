import {$host} from "./index";


export const getData = async ()=>{
    const {data} = await $host.get('data', {
         headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'ngrok-skip-browser-warning': '65430'
        }
    })

   // const filt = data.filter(d => d.type ===0);
    return data ///filt
}


export const postTask = async (rtuId, devid, task) => {
    const {data} = await $host.put('/api/task/device',{},
    {
        params :  { rtuId: rtuId, deviceId: devid, deviceTask: task},
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'ngrok-skip-browser-warning': '65430'
        }
    })
    return data
}
