import React, {useContext, useEffect, useState}  from 'react';
import {BrowserRouter} from "react-router-dom";
import AppRouter from "./components/AppRouter";
import NavBar from "./components/NavBar";
import {observer} from "mobx-react-lite";
import {Context} from "./index";
import  {getData} from "./http/deviceAPI"
import {Spinner} from "react-bootstrap";

const App = observer(() => {

    const {user} = useContext(Context)
    const {device} = useContext(Context)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
    const data = getData().then(()=>{
        device.setDevices(data)
        user.setUser(true)
        user.setIsAuth(true)
    }).finally(() => setLoading(false))}, [device,user])

    if (loading) {
        return <Spinner animation={"border"} variant="success"/>
    }

    return (
        <BrowserRouter>
            <NavBar />
            <AppRouter />
        </BrowserRouter>
    );
});

export default App;
