import {$host} from "./index";

import jwt_decode from "jwt-decode";


export const login = async (password) => {
    const {data} = await $host.get('auth',{params: {password: password},
      headers: {
            'Access-Control-Allow-Origin' : '*',
            'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            'Access-Control-Allow-Headers':' Origin, Content-Type : application/json, X-Auth-Token,X-Requested-With,Accept',
            'Access-Control-Allow-Credentials' : 'true',
            'ngrok-skip-browser-warning': '65430'
        }})
    localStorage.setItem('token', data)
    return jwt_decode(data)
}


