import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import {getData,postTask} from "../http/deviceAPI";
import {Container ,Button, Accordion, Card, Table} from "react-bootstrap";
import {Spinner} from "react-bootstrap";

const Pages = observer(() => {
    const {device} = useContext(Context)
    let d = device.devices

     if(d.length!=null) {

        return (

            <>
                <div>
                     <Accordion>
                        {d.map(d =>
                            <Card key={d.deviceId + (d.rtuId * d.calibration.counter)} bg={'dark'} text={'light'}>
                                <Card.Header>
                                    <Button className='btn-group-sm' size='sm' style={{
                                        marginRight: '10px', marginBottom:'5px',
                                        backgroundColor: ((d.task.state === 3 & d.task.type === 2) ? 'red' : (d.task.state === 1 & d.task.type === 2) ? 'darkviolet' : (d.task.state === 2 & d.task.type === 2) ? 'green' : (d.task.state === 4 & d.task.type === 2) ? 'yellow' : 'blue'),
                                        width: 53
                                    }}
                                            onClick={
                                                async () => {
                                                    try {
                                                        await postTask(d.rtuId,d.deviceId, '2')
                                                        device.setDevices(await getData())
                                                    } catch (e) {
                                                        alert(e.response.data.message)
                                                    }
                                                }}>   0% </Button>
                                    <Button className='btn-group-sm' size='sm' style={{
                                        marginRight: '10px', marginBottom:'5px',
                                        backgroundColor: ((d.task.state === 3 & d.task.type === 3) ? 'red' : (d.task.state === 1 & d.task.type === 3) ? 'darkviolet' : (d.task.state === 2 & d.task.type === 3) ? 'green' : (d.task.state === 4 & d.task.type === 3) ? 'yellow' : 'blue'),
                                        width: 53
                                    }}
                                            onClick={
                                                async () => {
                                                    try {
                                                        await postTask(d.rtuId,d.deviceId, '3')
                                                        device.setDevices(await getData())
                                                    } catch (e) {
                                                        alert(e.response.data.message)
                                                    }
                                                }}> 25% </Button>
                                    <Button className='btn-group-sm' size='sm' style={{
                                        marginRight: '10px', marginBottom:'5px',
                                        backgroundColor: ((d.task.state === 3 & d.task.type === 4) ? 'red' : (d.task.state === 1 & d.task.type === 4) ? 'darkviolet' : (d.task.state === 2 & d.task.type === 4) ? 'green' : (d.task.state === 4 & d.task.type === 4) ? 'yellow' : 'blue'),
                                        width: 53
                                    }}
                                            onClick={
                                                async () => {
                                                    try {
                                                        await postTask(d.rtuId,d.deviceId, '4')
                                                        device.setDevices(await getData())
                                                    } catch (e) {
                                                        alert(e.response.data.message)
                                                    }
                                                }}> 50% </Button>
                                    <Button className='btn-group-sm' size='sm' style={{
                                        marginRight: '10px', marginBottom:'5px',
                                        backgroundColor: ((d.task.state === 3 & d.task.type === 5) ? 'red' : (d.task.state === 1 & d.task.type === 5) ? 'darkviolet' : (d.task.state === 2 & d.task.type === 5) ? 'green' : (d.task.state === 4 & d.task.type === 5) ? 'yellow' : 'blue'),
                                        width: 53
                                    }}
                                            onClick={
                                                async () => {
                                                    try {
                                                        await postTask(d.rtuId,d.deviceId, '5')
                                                        device.setDevices(await getData())
                                                    } catch (e) {
                                                        alert(e.response.data.message)
                                                    }
                                                }}> 75% </Button>
                                    <Button className='btn-group-sm' size='sm' style={{
                                        marginRight: '10px', marginBottom:'5px',
                                        backgroundColor: ((d.task.state === 3 & d.task.type === 6) ? 'red' : (d.task.state === 1 & d.task.type === 6) ? 'darkviolet' : (d.task.state === 2 & d.task.type === 6) ? 'green' : (d.task.state === 4 & d.task.type === 6) ? 'yellow' : 'blue'),
                                        width: 53
                                    }}
                                            onClick={
                                                async () => {
                                                    try {
                                                        await postTask(d.rtuId,d.deviceId, '6')
                                                        device.setDevices(await getData())
                                                    } catch (e) {
                                                        alert(e.response.data.message)
                                                    }
                                                }}> 100% </Button>
                                    <Button className='btn-group-sm' size='sm' style={{
                                        marginRight: '10px', marginBottom:'5px',
                                        backgroundColor: ((d.task.state === 3 & d.task.type === 1) ? 'red' : (d.task.state === 1 & d.task.type === 1) ? 'darkviolet' : (d.task.state === 2 & d.task.type === 1) ? 'green' : (d.task.state === 4 & d.task.type === 1) ? 'yellow' : 'blue'),
                                        width: 53
                                    }}
                                            onClick={
                                                async () => {
                                                    try {
                                                        await postTask(d.rtuId,d.deviceId, '1')
                                                        device.setDevices(await getData())
                                                    } catch (e) {
                                                        alert(e.response.data.message)
                                                    }
                                                }}> 1/1 </Button>
                                    <Accordion.Toggle as={Button} variant='link' style={{color:(d.task.state === -1 ? 'red' : 'white')}}  eventKey={d.deviceId * d.rtuId}>
                                        {d.deviceName}
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey={d.deviceId * d.rtuId}>
                                    <Card.Body>
                                        <Container>
                                            <Table key={d.deviceId + '23'} responsive striped bordered hover size="sm" variant={'dark'} className='table-light' >
                                                <thead align={'center'}>
                                                <tr>
                                                    <th>{d.calibration.timestamp}</th>
                                                    <th>New</th>
                                                    <th>Prev</th>
                                                </tr>
                                                </thead>
                                                <tbody align={'center'}>
                                                <tr>
                                                    <td>Counter</td>
                                                    <td>{d.calibration.counter}</td>
                                                    <td> - </td>
                                                </tr>
                                                <tr>
                                                    <td>rawZero</td>
                                                    <td>{d.calibration.rawZero}</td>
                                                    <td>{d.calibration.prevRawZero}</td>
                                                </tr>
                                                <tr>
                                                    <td>euMid</td>
                                                    <td>{d.calibration.euMid}</td>
                                                    <td>{d.calibration.prevEuMid}</td>
                                                </tr>
                                                <tr>
                                                    <td>rawMid</td>
                                                    <td>{d.calibration.rawMid}</td>
                                                    <td>{d.calibration.prevRawMid}</td>
                                                </tr>
                                                <tr>
                                                    <td>euSpan</td>
                                                    <td>{d.calibration.euSpan}</td>
                                                    <td>{d.calibration.prevEuSpan}</td>
                                                </tr>
                                                <tr>
                                                    <td>rawSpan</td>
                                                    <td>{d.calibration.rawSpan}</td>
                                                    <td>{d.calibration.prevRawSpan}</td>
                                                </tr>

                                                </tbody>
                                            </Table>

                                            <Table key={d.deviceId + '48'} responsive striped bordered hover size="sm" variant={'dark'} className='table-light' >
                                                <thead align={'center'}>
                                                <tr>
                                                    <th>Date-Time</th>
                                                    <th>{d.instant.timestamp}</th>
                                                </tr>
                                                </thead>
                                                <tbody align={'center'}>
                                                <tr>
                                                    <td>Curr Hour Vol M3</td>
                                                    <td>{d.instant.currHourVol}</td>
                                                </tr>
                                                <tr>
                                                    <td>Prev Hour Vol M3</td>
                                                    <td>{d.instant.prevHourVol}</td>
                                                </tr>
                                                <tr>
                                                    <td>Hourly Flow Rate M3/H</td>
                                                    <td>{d.instant.hourlyFlowRate}</td>
                                                </tr>
                                                <tr>
                                                    <td>Dayly  Flow Rate M3/D</td>
                                                    <td>{d.instant.dailyFlowRate}</td>
                                                </tr>
                                                <tr>
                                                    <td>Curr Day Vol M3</td>
                                                    <td>{d.instant.currDayVol}</td>
                                                </tr>
                                                <tr>
                                                    <td>Prev Day Vol M3</td>
                                                    <td>{d.instant.prevDayVol}</td>
                                                </tr>

                                                </tbody>
                                            </Table>
                                        </Container>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        )}

                    </Accordion>
                </div>
            </>


        );
           }else{
               return (
                   <Spinner animation={"border"} variant="success"/>

               );
           }

});

export default Pages;
