import React, {useContext, useEffect} from 'react';
import {Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import {getData} from "../http/deviceAPI";
import Pages from "../components/Pages";




const Shop = observer(() => {
    const {device} = useContext(Context)


    //useEffect(async () => {await getData().then(data=> device.setDevices(data))}, [])

    useEffect(()=>{
        async function tick() {
            device.setDevices(await getData())
        }
        tick()
    },[device]);

    async function tick() {
       device.setDevices(await getData())
    }



    setInterval(tick, 60000);

    return (
        <Container>
            <Row className="mt-2">
                   <Col >
                    <Pages/>
                </Col>
            </Row>
        </Container>
    );
});

export default Shop;
